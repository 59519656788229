<template>
  <div>
    <div class="item2 mt-3">
      <b-row>
        <!-- <b-col> <legend> UBICACIÓN DEL NUEVO DOMICILIO ELECTORAL</legend>   </b-col>   -->
      </b-row>
      <b-row>
        <b-col>
          <b-alert variant="danger" show>
            <!-- El domicilio electoral es el registrado en Servel, situado dentro de
            Chile con el cual tiene un vínculo objetivo, sea porque reside
            temporal o habitualmente, ejerce su profesión u oficio, o desarrolla
            sus estudios en él. En el caso de los ciudadanos que se encuentran
            en el extranjero, es aquel situado fuera de Chile, declarado como
            tal por el elector. <br /> -->
            <b
              >La solicitud de cambio de domicilio electoral constituye una
              declaración jurada</b
            ><br />Si su domicilio electoral actual está en una ciudad de Chile,
            presione la opción “Chile Urbano”. Si está en un área rural,
            localidad, poblado o similar seleccione “Chile Rural”. Si está fuera
            de Chile marque “En el extranjero”.
          </b-alert>
        </b-col>
      </b-row>
      <hr class="mt-0 mb-0" />
      <b-row>
        <b-col>
          <strong>Ubicaciones <span class="text-danger">(*)</span> </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-list-group sm="12" horizontal="md">
            <b-list-group-item class="flex-column align-items-center">
              <b-button
                :variant="
                  ubicacion == 'chile-urbano' || ubicacion == 'chile-geo'
                    ? 'gobierno'
                    : 'outline-gobierno'
                "
                @click="cambiar(mapaActivo ? 'chile-geo' : 'chile-urbano')"
                class="flex-column align-items-center"
                block
                v-b-tooltip.hover
                title="Su domicilio electoral se encuentra ubicado en una ciudad."
              >
                <div class="d-flex w-100 justify-content-center">
                  <h5 class="mb-1">EN CHILE-URBANO</h5>
                </div>
              </b-button>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-center">
              <b-button
                :variant="
                  ubicacion == 'chile-rural' ? 'gobierno' : 'outline-gobierno'
                "
                @click="cambiar('chile-rural')"
                block
                v-b-tooltip.hover
                title="Su domicilio electoral se encuentra en un territorio con escasa cantidad de habitantes (poblado, localidad, parcela, entre otros)."
              >
                <div class="d-flex w-100 justify-content-center">
                  <h5 class="mb-1">EN CHILE-RURAL</h5>
                </div>
              </b-button>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-center">
              <b-button
                :variant="
                  ubicacion == 'extranjero' ? 'gobierno' : 'outline-gobierno'
                "
                :disabled="
                  (incorporacion && ischile != 'CL') ||
                  (!incorporacion && registro.nacionalidad != 'CL')
                "
                @click="cambiar('extranjero')"
                block
                v-b-tooltip.hover
                title="Su domicilio electoral se encuentra fuera de Chile."
              >
                <div class="d-flex w-100 justify-content-center">
                  <h5 class="mb-1">EN EL EXTRANJERO</h5>
                </div>
              </b-button>
            </b-list-group-item>
          </b-list-group>
          <p class="mb-0 text-danger" v-if="!$v.ubicacion.required">
            (*) Necesita elegir una ubicación
          </p>
        </b-col>
      </b-row>
      <div v-if="ubicacion != ''" class="mt-3">
        <b-row>
          <b-col>
            <legend>INGRESO DEL NUEVO DOMICILIO ELECTORAL</legend>
          </b-col>
        </b-row>
        <b-row v-if="ubicacion == 'chile-urbano'"
          ><b-alert variant="danger" show>
            No fue posible obtener una coordenada exacta para su domicilio
            electoral. Dado eso, le pedimos complete el siguiente formulario
          </b-alert></b-row
        >
        <hr class="mt-0 mb-0" />
        <b-row v-if="ubicacion == 'chile-geo'">
          <!-- <b-row v-if="ubicacion =='chile-urbano'"> -->

          <b-col sm="12">
            <span>
              <!-- GEOLOCALIZACIÓN OPCIONAL  -->
              <!-- IR A FORMULARIO MANUAL -->
            </span>
          </b-col>
          <!-- <b-col sm="12">
            <span>
              <p class="mb-0">
                Para obtener una correcta geolocalización, digite su calle,
                número, comuna y región. Revise lo digitado.
              </p>
              <p class="mt-0">
                Si se despliega más de una opción, seleccione la que corresponda
                a su domicilio electoral.
              </p>
            </span>
          </b-col> -->
        </b-row>

        <div v-if="ubicacion == 'chile-geo'">
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  Región <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formChileGeo.region.$invalid"
                  :list="regiones"
                  v-model="formChileGeo.region"
                  option-value="cod_region"
                  option-text="region"
                  placeholder="seleccionar región"
                >
                </model-list-select> -->
                <b-form-select
                  :class="{
                    'invalid-gobierno': $v.formChileGeo.region.$invalid,
                    'select-gobierno': !$v.formChileGeo.region.$invalid,
                  }"
                  v-model="formChileGeo.region"
                  :options="regiones"
                  value-field="cod_region"
                  text-field="region"
                  @change="resetComuna(formChileGeo)"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar región</b-form-select-option
                  ></b-form-select
                >
                <p
                  v-if="!$v.formChileGeo.region.required"
                  class="invalid-feedback mb-0"
                >
                  La región es requerida
                </p>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <!-- {{ comunas }} -->
              <b-form-group>
                <legend class="label-gobierno">
                  Comuna <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  ref="refcomuna"
                  autocomplete="nope"
                  :isError="$v.formChileGeo.comuna.$invalid"
                  v-model="formChileGeo.comuna"
                  :list="comunas"
                  option-value="comuna"
                  option-text="comuna"
                  placeholder="seleccionar comuna"
                  @input="selectComuna"
                >
                </model-list-select> -->
                <b-form-select
                  ref="refcomuna"
                  :class="{
                    'invalid-gobierno': $v.formChileGeo.comuna.$invalid,
                    'select-gobierno': !$v.formChileGeo.comuna.$invalid,
                  }"
                  v-model="formChileGeo.comuna"
                  :options="comunas"
                  value-field="comuna"
                  text-field="comuna"
                  @input="selectComuna"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar comuna</b-form-select-option
                  ></b-form-select
                >
                <p
                  v-if="!$v.formChileGeo.comuna.required"
                  class="invalid-feedback mb-0"
                >
                  La comuna es requerida
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group> </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4 mt-1">
            <b-col sm="12">
              <b-form-group>
                <legend class="label-gobierno">
                  Ingrese calle y número <span class="text-danger">(*)</span>
                </legend>
                <small class="secondary-gobierno">
                  En caso de que Google reconozca su dirección se desplegarán
                  opciones, dentro de las que debe seleccionar la que
                  corresponda.
                </small>
                <GoogleAutoComplete
                  v-on:clear_input_auto="clearinputAuto()"
                  v-on:g_set_position="gSetPosition($event)"
                  v-on:blur="checkAutocomplete()"
                  :estadoDelAuto="estadoDelAuto"
                  :class_calle="class_calle"
                  ref="gAuto"
                />
                <p
                  v-if="!$v.formChileGeo.calle.required"
                  class="invalid-feedback mb-0"
                >
                  Ingrese una dirección
                </p>
                <p
                  v-if="!$v.formChileGeo.calle.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 300 caracteres
                </p>
              </b-form-group>

              <b-form-group
                ><legend class="label-gobierno">
                  Indique N° Depto / Block / Letra Casa (solo si corresponde)
                  <span class="text-danger">(*)</span>
                </legend>
                <b-form-radio-group
                  id="radio-group"
                  v-model="formChileGeo.tipo_residencia"
                  :options="residenciaOpciones"
                  name="radio-residencia"
                  class="radio-gobierno"
                ></b-form-radio-group
              ></b-form-group>
              <b-form-group v-if="formChileGeo.tipo_residencia">
                <b-form-input
                  autocomplete="nope"
                  v-model="formChileGeo.block_depto"
                  type="text"
                  placeholder="Ej: 0094"
                  :class="{
                    'invalid-gobierno': $v.formChileGeo.block_depto.$invalid,
                    'input-gobierno': !$v.formChileGeo.block_depto.$invalid,
                  }"
                  required
                  style="width: 25%; margin-top: 4px"
                ></b-form-input>
                <p
                  v-if="!$v.formChileGeo.block_depto.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 120 caracteres
                </p>
              </b-form-group>

              <b-form-group>
                <div style="width: 100%; height: 83%; min-height: 300px">
                  <b
                    >Revise que el punto asociado a su dirección sea correcto.
                    Si no lo es, muévalo</b
                  >
                  <div
                    style="width: 100%; height: 83%; min-height: 300px"
                    ref="mapa"
                  >
                    <GmapMap
                      @click="handleClick"
                      @idle="cargaMapa"
                      :options="{
                        zoomControl: true,
                        zoomControlOptions: {
                          position: 6,
                        },
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                          position: 9,
                        },
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false,
                        gestureHandling: 'greedy',
                      }"
                      v-if="formChileGeo.geo"
                      :center="{
                        lat: formChileGeo.geo.position.lat,
                        lng: formChileGeo.geo.position.lng,
                      }"
                      :zoom="mapZoom"
                      map-type-id="terrain"
                      ref="map"
                      id="map"
                      style="width: 100%; height: 100%; min-height: 300px"
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in marcas"
                        :position="m.position"
                        :clickable="false"
                        :draggable="true"
                        @dragend="gMapFunc($event.latLng)"
                      />
                    </GmapMap>
                  </div>
                </div>
                <div></div>
                <div class="mt-3">
                  <small
                    >¿Su dirección no aparece? Ingrese al formulario manual
                  </small>
                  <b-button
                    @click="cambiar('chile-urbano')"
                    size="sm"
                    variant="outline-success"
                    >Formulario manual
                  </b-button>
                </div></b-form-group
              >

              <b-form-group
                ><legend class="label-gobierno">
                  Correo Electrónico (de uso personal y/o privado)
                  <span class="text-danger">(*)</span>
                </legend>
                <b-form-input
                  :class="{
                    'invalid-gobierno': $v.formComun.email.$invalid,
                    'input-gobierno': !$v.formComun.email.$invalid,
                  }"
                  v-model="formComun.email"
                  type="text"
                  placeholder="Ingresar Email"
                  required
                ></b-form-input>
                <p
                  v-if="!$v.formComun.email.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 50 caracteres
                </p>
                <p
                  v-if="!$v.formComun.email.required"
                  class="invalid-feedback mb-0"
                >
                  El correo es requerido
                </p>
                <p
                  v-if="!$v.formComun.email.email"
                  class="invalid-feedback mb-0"
                >
                  Formato de correo incorrecto
                </p>
              </b-form-group>

              <b-form-group>
                <legend class="label-gobierno">
                  Confirmar correo electrónico
                  <span class="text-danger">(*)</span>
                </legend>
                <b-form-input
                  :class="{
                    'invalid-gobierno': $v.formComun.email2.$invalid,
                    'input-gobierno': !$v.formComun.email2.$invalid,
                  }"
                  v-model="formComun.email2"
                  type="text"
                  placeholder="Ingresar Email"
                  required
                ></b-form-input>
                <p
                  v-if="!$v.formComun.email2.required"
                  class="invalid-feedback mb-0"
                >
                  Confirmar correo es requerido
                </p>
                <p
                  v-if="!$v.formComun.email2.email"
                  class="invalid-feedback mb-0"
                >
                  Formato de correo incorrecto
                </p>
                <p
                  v-if="!$v.formComun.email2.sameAs"
                  class="invalid-feedback mb-0"
                >
                  Los correos no son iguales
                </p>
              </b-form-group>
            </b-col>
            <!-- <b-col sm="6">
              <div style="width: 100%; height: 83%; min-height: 200px">
                Revise que el punto asociado a su dirección sea correcto. Si no
                lo es, muévalo
                <div
                  style="width: 100%; height: 83%; min-height: 200px"
                  ref="mapa"
                >
                  <GmapMap
                    @click="handleClick"
                    :options="{
                      zoomControl: true,
                      zoomControlOptions: {
                        position: 6,
                      },
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: false,
                      gestureHandling: 'greedy',
                    }"
                    v-if="formChileGeo.geo"
                    :center="{
                      lat: formChileGeo.geo.position.lat,
                      lng: formChileGeo.geo.position.lng,
                    }"
                    :zoom="mapZoom"
                    map-type-id="terrain"
                    ref="map"
                    id="map"
                    style="width: 100%; height: 100%; min-height: 200px"
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in marcas"
                      :position="m.position"
                      :clickable="false"
                      :draggable="true"
                      @dragend="gMapFunc($event.latLng)"
                    />
                  </GmapMap>
                </div>
              </div>
            </b-col>
            <b-col sm="6">
              <div></div>
            </b-col>
            <b-col sm="6">
              <div>
                <small
                  >¿Su dirección no aparece? Ingrese al formulario manual
                </small>
                <b-button
                  @click="cambiar('chile-urbano')"
                  size="sm"
                  variant="outline-success"
                  >Formulario manual
                </b-button>
              </div>
            </b-col> -->
          </b-row>
        </div>

        <div v-if="ubicacion == 'chile-urbano'">
          <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col>
              <!-- <b-button 
                                class="mt-4"
                                variant="outline-primary" 
                                block
                                @click="cambiar('chile-geo')" 
                                >
                                <b-icon icon="geo-alt-fill"></b-icon> Geolocalización opcional
                            
                            </b-button>   -->
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  Región <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formChileUrbano.region.$invalid"
                  :list="regiones"
                  v-model="formChileUrbano.region"
                  option-value="cod_region"
                  option-text="region"
                  placeholder="seleccionar región"
                >
                </model-list-select> -->
                <b-form-select
                  :class="{
                    'invalid-gobierno': $v.formChileUrbano.region.$invalid,
                    'select-gobierno': !$v.formChileUrbano.region.$invalid,
                  }"
                  v-model="formChileUrbano.region"
                  :options="regiones"
                  value-field="cod_region"
                  text-field="region"
                  @change="resetComuna(formChileUrbano)"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar región</b-form-select-option
                  ></b-form-select
                >
                <p
                  v-if="!$v.formChileUrbano.region.required"
                  class="invalid-feedback mb-0"
                >
                  La región es requerido
                </p>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  Comuna <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formChileUrbano.comuna.$invalid"
                  v-model="formChileUrbano.comuna"
                  :list="comunas"
                  option-value="cod_comuna"
                  option-text="comuna"
                  placeholder="seleccionar comuna"
                  @input="selectComuna"
                >
                </model-list-select> -->
                <b-form-select
                  ref="refcomuna"
                  :class="{
                    'invalid-gobierno': $v.formChileUrbano.comuna.$invalid,
                    'select-gobierno': !$v.formChileUrbano.comuna.$invalid,
                  }"
                  v-model="formChileUrbano.comuna"
                  :options="comunas"
                  value-field="cod_comuna"
                  text-field="comuna"
                  @input="selectComuna"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar comuna</b-form-select-option
                  ></b-form-select
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-row>
                <b-col sm="8">
                  <b-form-group>
                    <legend class="label-gobierno">
                      Ingrese la calle, avenida, pasaje u otro
                      <span class="text-danger">(*)</span>
                    </legend>
                    <b-form-input
                      autocomplete="nope"
                      :class="{
                        'invalid-gobierno': $v.formChileUrbano.calle.$invalid,
                        'input-gobierno': !$v.formChileUrbano.calle.$invalid,
                      }"
                      v-model="formChileUrbano.calle"
                      type="text"
                      placeholder="Ej: esmeralda"
                      required
                    >
                    </b-form-input>
                    <p
                      v-if="!$v.formChileUrbano.calle.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 120 caracteres
                    </p>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group>
                    <legend class="label-gobierno">Número</legend>
                    <b-form-input
                      autocomplete="nope"
                      :class="{
                        'invalid-gobierno': $v.formChileUrbano.numero.$invalid,
                        'input-gobierno': !$v.formChileUrbano.numero.$invalid,
                      }"
                      v-model="formChileUrbano.numero"
                      type="text"
                      placeholder="Ej: 0094"
                      required
                    ></b-form-input>
                    <p
                      v-if="!$v.formChileUrbano.numero.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 10 caracteres
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                ><b-col sm="12"
                  ><b-form-group
                    ><legend class="label-gobierno">
                      Indique N° Depto / Block / Letra Casa (solo si
                      corresponde) <span class="text-danger">(*)</span>
                    </legend>
                    <b-form-radio-group
                      id="radio-group"
                      v-model="formChileUrbano.tipo_residencia"
                      :options="residenciaOpciones"
                      name="radio-residencia"
                      class="radio-gobierno"
                    ></b-form-radio-group></b-form-group></b-col
                ><b-col sm="12" v-if="formChileUrbano.tipo_residencia">
                  <b-form-group>
                    <b-form-input
                      autocomplete="nope"
                      v-model="formChileUrbano.block_depto"
                      type="text"
                      placeholder="Ej: 0094"
                      :class="{
                        'invalid-gobierno':
                          $v.formChileUrbano.block_depto.$invalid,
                        'input-gobierno':
                          !$v.formChileUrbano.block_depto.$invalid,
                      }"
                      required
                      style="width: 25%; margin-top: 4px"
                    ></b-form-input>
                    <p
                      v-if="!$v.formChileUrbano.block_depto.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 120 caracteres
                    </p>
                  </b-form-group>
                </b-col></b-row
              >
              <b-row>
                <b-col sm="12">
                  <b-form-group>
                    <legend class="label-gobierno">
                      Indique Barrio/Villa/Condominio/Población/Sector
                      <span class="text-danger">(*)</span>
                    </legend>
                    <label class="secondary-gobierno">
                      <b
                        ><u>
                          El dato que ingrese a continuación permitirá mostrar
                          una ubicación aproximada de su domicilio electoral en
                          un mapa</u
                        ></b
                      >
                    </label>
                    <!-- <vue-bootstrap-autocomplete
                      v-model="formChileUrbano.villa_poblacion"
                      :data="localidades"
                      type="text"
                      placeholder="Ej: Los prados"
                      :serializer="(loc) => loc.localidad"
                      @input="() => (this.exacto = false)"
                      @hit="selectLocalidad($event)"
                      showOnFocus
                      :maxMatches="50"
                    /> -->
                    <!-- <b-form-select
                      v-on:change="selectLocalidad($event)"
                      v-model="localidadSeleccionada"
                      ><b-form-select-option :value="null" disabled
                        >seleccionar localidad</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="localidad in localidades"
                        :value="localidad"
                        :key="localidad.localidad"
                      >
                        {{ localidad.localidad }}
                      </b-form-select-option></b-form-select
                    > -->
                    <model-list-select
                      v-model="localidadSeleccionada"
                      :list="localidades"
                      option-value="localidad"
                      option-text="localidad"
                      placeholder="seleccionar localidad"
                      @input="selectLocalidad($event)"
                      :isDisabled="checkOtro"
                      ref="check_otro"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                ><b-col sm="12">
                  <b-form-group>
                    <label class="secondary-gobierno">
                      <b
                        ><u>
                          Si no logra encontrar su localidad en la lista,
                          seleccione el check ‘otro’ y especifique el nombre de
                          su localidad.</u
                        ></b
                      >
                    </label>
                    <b-form-checkbox
                      v-model="checkOtro"
                      name="check_otro"
                      :unchecked-value="false"
                      :checked="checkOtro"
                      class="check-gobierno"
                      @input="check($event)"
                    >
                      Otro
                    </b-form-checkbox>
                  </b-form-group></b-col
                ></b-row
              >
              <b-row v-if="!exacto && localidadSeleccionada">
                <b-col sm="12"
                  ><b-form-group>
                    <legend class="label-gobierno">
                      Especifique <span class="text-danger">(*)</span>
                    </legend>
                    <b-form-input
                      autocomplete="nope"
                      :class="{
                        'is-invalid':
                          $v.formChileUrbano.villa_poblacion.$invalid,
                      }"
                      v-model="formChileUrbano.villa_poblacion"
                      type="text"
                      placeholder="Ej: Bella Artes"
                      required
                    >
                    </b-form-input>
                    <p
                      v-if="!$v.formChileUrbano.villa_poblacion.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 120 caracteres
                    </p>
                  </b-form-group></b-col
                ></b-row
              > </b-col
            ><b-col sm="12"
              ><div
                style="width: 100%; height: 83%; min-height: 300px"
                v-if="formChileUrbano.geo && mapaActivo"
              >
                Localización aproximada según datos provistos. Para mejorarla,
                mueva el pin.
                <div
                  class="mb-3"
                  style="width: 100%; height: 83%; min-height: 300px"
                  ref="mapa"
                >
                  <GmapMap
                    @click="handleClick"
                    @idle="cargaMapa"
                    :options="{
                      zoomControl: true,
                      zoomControlOptions: {
                        position: 6,
                      },
                      mapTypeControl: true,
                      mapTypeControlOptions: {
                        position: 9,
                      },
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: false,
                      gestureHandling: 'greedy',
                    }"
                    :center="{
                      lat: formChileUrbano.geo.position.lat,
                      lng: formChileUrbano.geo.position.lng,
                    }"
                    :zoom="mapZoom"
                    map-type-id="terrain"
                    ref="map"
                    id="map"
                    style="width: 100%; height: 100%; min-height: 300px"
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in marcas"
                      :position="m.position"
                      :clickable="false"
                      :draggable="true"
                      @dragend="gMapFunc($event.latLng)"
                    />
                  </GmapMap>
                </div></div></b-col
          ></b-row>
        </div>

        <div v-if="ubicacion == 'chile-rural'">
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  Región <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formChileRural.region.$invalid"
                  v-model="formChileRural.region"
                  :list="regiones"
                  option-value="cod_region"
                  option-text="region"
                  placeholder="seleccionar región"
                >
                </model-list-select> -->
                <b-form-select
                  :class="{
                    'invalid-gobierno': $v.formChileRural.region.$invalid,
                    'select-gobierno': !$v.formChileRural.region.$invalid,
                  }"
                  v-model="formChileRural.region"
                  :options="regiones"
                  value-field="cod_region"
                  text-field="region"
                  @change="resetComuna(formChileRural)"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar región</b-form-select-option
                  ></b-form-select
                >
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="">
                <legend class="label-gobierno">
                  Comuna <span class="text-danger">(*)</span>
                </legend>
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formChileRural.comuna.$invalid"
                  v-model="formChileRural.comuna"
                  :list="comunas"
                  option-value="cod_comuna"
                  option-text="comuna"
                  placeholder="seleccionar comuna"
                  @input="selectComuna"
                >
                </model-list-select> -->
                <b-form-select
                  ref="refcomuna"
                  :class="{
                    'invalid-gobierno': $v.formChileRural.comuna.$invalid,
                    'select-gobierno': !$v.formChileRural.comuna.$invalid,
                  }"
                  v-model="formChileRural.comuna"
                  :options="comunas"
                  value-field="cod_comuna"
                  text-field="comuna"
                  @input="selectComuna"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar comuna</b-form-select-option
                  ></b-form-select
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-row>
                <b-col sm="12">
                  <b-form-group>
                    <legend class="label-gobierno">
                      Calle / Parcela / Sitio / Carretera-KM
                      <span class="text-danger">(*)</span>
                    </legend>
                    <b-form-input
                      autocomplete="nope"
                      :class="{
                        'invalid-gobierno': $v.formChileRural.calle.$invalid,
                        'input-gobierno': !$v.formChileRural.calle.$invalid,
                      }"
                      v-model="formChileRural.calle"
                      type="text"
                      placeholder="Ingrese dirección Ej: camino interior "
                      required
                    ></b-form-input>
                    <p
                      v-if="!$v.formChileRural.calle.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 120 caracteres
                    </p>
                    <p
                      v-if="!$v.formChileRural.calle.required"
                      class="invalid-feedback mb-0"
                    >
                      El campo es requerido
                    </p>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group>
                    <legend class="label-gobierno">Número</legend>
                    <b-form-input
                      autocomplete="nope"
                      :class="{
                        'invalid-gobierno': $v.formChileRural.numero.$invalid,
                        'input-gobierno': !$v.formChileRural.numero.$invalid,
                      }"
                      v-model="formChileRural.numero"
                      type="text"
                      placeholder="ej: 0000 "
                      required
                    ></b-form-input>
                    <p
                      v-if="!$v.formChileRural.numero.maxLength"
                      class="invalid-feedback mb-0"
                    >
                      El maximo permitido es de 10 caracteres
                    </p>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                ><b-col sm="12">
                  <b-form-group>
                    <legend class="label-gobierno">
                      Indique Zona/Sector/Área Rural/Localidad
                      <span class="text-danger">(*)</span>
                    </legend>
                    <label class="secondary-gobierno">
                      <b
                        ><u>
                          El dato que ingrese a continuación permitirá mostrar
                          una ubicación aproximada de su domicilio electoral en
                          un mapa</u
                        ></b
                      >
                    </label>
                    <!-- <vue-bootstrap-autocomplete
                      v-model="formChileRural.localidad"
                      :data="localidades"
                      placeholder="Ingrese nombre de la localidad"
                      :serializer="(loc) => loc.localidad"
                      @input="() => (this.exacto = false)"
                      @hit="selectLocalidad($event)"
                      showOnFocus
                      :maxMatches="50"
                    /> -->
                    <!-- <b-form-select
                      v-on:change="selectLocalidad($event)"
                      v-model="localidadSeleccionada"
                      ><b-form-select-option :value="null" disabled
                        >seleccionar localidad</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="localidad in localidades"
                        :value="localidad"
                        :key="localidad.localidad"
                      >
                        {{ localidad.localidad }}
                      </b-form-select-option></b-form-select
                    > -->
                    <model-list-select
                      v-model="localidadSeleccionada"
                      :list="localidades"
                      option-value="localidad"
                      option-text="localidad"
                      placeholder="seleccionar localidad"
                      @input="selectLocalidad($event)"
                      :isDisabled="checkOtro"
                      ref="check_otro"
                    ></model-list-select>
                    <b-row
                      ><b-col sm="12">
                        <b-form-group>
                          <label class="secondary-gobierno">
                            <b
                              ><u>
                                Si no logra encontrar su localidad en la lista,
                                seleccione el check ‘otro’ y especifique el
                                nombre de su localidad.</u
                              ></b
                            >
                          </label>
                          <b-form-checkbox
                            v-model="checkOtro"
                            name="check_otro"
                            :unchecked-value="false"
                            :checked="checkOtro"
                            class="check-gobierno"
                            @input="check($event)"
                          >
                            Otro
                          </b-form-checkbox>
                        </b-form-group></b-col
                      ></b-row
                    >
                    <b-row v-if="!exacto && localidadSeleccionada">
                      <b-col sm="12"
                        ><b-form-group>
                          <legend class="label-gobierno">
                            Especifique
                            <span class="text-danger">(*)</span>
                          </legend>
                          <b-form-input
                            autocomplete="nope"
                            :class="{
                              'is-invalid':
                                $v.formChileRural.localidad.$invalid,
                            }"
                            v-model="formChileRural.localidad"
                            type="text"
                            placeholder="Ej: Bella Artes"
                            required
                          >
                          </b-form-input>
                          <p
                            v-if="!$v.formChileRural.localidad.maxLength"
                            class="invalid-feedback mb-0"
                          >
                            El maximo permitido es de 120 caracteres
                          </p>
                        </b-form-group></b-col
                      ></b-row
                    >
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="8">
              <b-form-group label="Referencia">
                <b-form-input
                  autocomplete="nope"
                  v-model="formChileRural.referencia"
                  type="text"
                  placeholder="Ingrese cualquier información adicional que permita ubicar su domicilio"
                ></b-form-input>
              </b-form-group> </b-col
          > -->
              </b-row> </b-col
            ><b-col sm="12"
              ><div
                class="mb-3"
                style="width: 100%; height: 83%; min-height: 300px"
                v-if="formChileRural.geo && mapaActivo"
              >
                Localización aproximada según datos provistos. Para mejorarla,
                mueva el pin.
                <div
                  style="width: 100%; height: 83%; min-height: 300px"
                  ref="mapa"
                >
                  <GmapMap
                    @click="handleClick"
                    @idle="cargaMapa"
                    :options="{
                      zoomControl: true,
                      zoomControlOptions: {
                        position: 6,
                      },
                      mapTypeControl: true,
                      mapTypeControlOptions: {
                        position: 9,
                      },
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: false,
                      gestureHandling: 'greedy',
                    }"
                    :center="{
                      lat: formChileRural.geo.position.lat,
                      lng: formChileRural.geo.position.lng,
                    }"
                    :zoom="mapZoom"
                    map-type-id="terrain"
                    ref="map"
                    id="map"
                    style="width: 100%; height: 100%; min-height: 300px"
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in marcas"
                      :position="m.position"
                      :clickable="false"
                      :draggable="true"
                      @dragend="gMapFunc($event.latLng)"
                    />
                  </GmapMap>
                </div></div></b-col
          ></b-row>
        </div>

        <div v-if="ubicacion == 'extranjero'">
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  País <span class="text-danger">(*)</span>
                </legend>
                <b-form-select
                  ref="refpais"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.pais.$invalid,
                    'select-gobierno': !$v.formExtranjero.pais.$invalid,
                  }"
                  v-model="formExtranjero.pais"
                  :options="paises"
                  value-field="cod_pais"
                  text-field="pais"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar país</b-form-select-option
                  ></b-form-select
                >
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formExtranjero.pais.$invalid"
                  v-model="formExtranjero.pais"
                  :list="paises"
                  option-value="cod_pais"
                  option-text="pais"
                  placeholder="seleccionar pais"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.pais.$invalid,
                    'select-gobierno': !$v.formExtranjero.pais.$invalid,
                  }"
                >
                </model-list-select> -->
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  División Territorial <span class="text-danger">(*)</span>
                </legend>
                <b-form-select
                  ref="refterritorio"
                  :class="{
                    'invalid-gobierno':
                      $v.formExtranjero.division_territorial.$invalid,
                    'select-gobierno':
                      !$v.formExtranjero.division_territorial.$invalid,
                  }"
                  v-model="formExtranjero.division_territorial"
                  :options="territorios"
                  value-field="cod_region"
                  text-field="region"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar territorio</b-form-select-option
                  ></b-form-select
                >
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formExtranjero.division_territorial.$invalid"
                  v-model="formExtranjero.division_territorial"
                  :list="territorios"
                  option-value="cod_region"
                  option-text="region"
                  placeholder="seleccionar territorio"
                >
                </model-list-select> -->
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">
                  Ciudad/Localidad <span class="text-danger">(*)</span>
                </legend>
                <b-form-select
                  ref="refciudad"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.ciudad.$invalid,
                    'select-gobierno': !$v.formExtranjero.ciudad.$invalid,
                  }"
                  v-model="formExtranjero.ciudad"
                  :options="ciudades"
                  value-field="cod_comuna"
                  text-field="comuna"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar ciudad</b-form-select-option
                  ></b-form-select
                >
                <!-- <model-list-select
                  autocomplete="nope"
                  :isError="$v.formExtranjero.ciudad.$invalid"
                  v-model="formExtranjero.ciudad"
                  :list="ciudades"
                  option-value="cod_comuna"
                  option-text="comuna"
                  placeholder="seleccionar ciudad"
                >
                </model-list-select> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group>
                <legend class="label-gobierno">
                  Calle <span class="text-danger">(*)</span>
                </legend>
                <b-form-input
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.calle.$invalid,
                    'input-gobierno': !$v.formExtranjero.calle.$invalid,
                  }"
                  autocomplete="nope"
                  v-model="formExtranjero.calle"
                  type="text"
                  placeholder="nombre de la calle o avenida Ej: esmeralda"
                  required
                ></b-form-input>
                <p
                  v-if="!$v.formExtranjero.calle.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 120 caracteres
                </p>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">Número</legend>
                <b-form-input
                  autocomplete="nope"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.numero.$invalid,
                    'input-gobierno': !$v.formExtranjero.numero.$invalid,
                  }"
                  v-model="formExtranjero.numero"
                  type="text"
                  placeholder="Ej: 0094"
                  required
                ></b-form-input>
                <p
                  v-if="!$v.formExtranjero.numero.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 10 caracteres
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group>
                <legend class="label-gobierno">Block / Departamento</legend>
                <b-form-input
                  autocomplete="nope"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.block_depto.$invalid,
                    'input-gobierno': !$v.formExtranjero.block_depto.$invalid,
                  }"
                  v-model="formExtranjero.block_depto"
                  type="text"
                  placeholder="Nombre del Block / Departamento"
                  required
                ></b-form-input>
                <p
                  v-if="!$v.formExtranjero.block_depto.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 120 caracteres
                </p>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <legend class="label-gobierno">Código Postal</legend>
                <b-form-input
                  autocomplete="nope"
                  :class="{
                    'invalid-gobierno': $v.formExtranjero.numero.$invalid,
                    'input-gobierno': !$v.formExtranjero.numero.$invalid,
                  }"
                  v-model="formExtranjero.codigo_postal"
                  type="text"
                  placeholder="Ej (santiago): 8320000 "
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <b-form-group label="">
                <legend class="label-gobierno">
                  Circunscripción <span class="text-danger">(*)</span>
                </legend>
                <b-form-select
                  ref="refcircunscripcion"
                  :class="{
                    'invalid-gobierno':
                      $v.formExtranjero.circunscripcion.$invalid,
                    'select-gobierno':
                      !$v.formExtranjero.circunscripcion.$invalid,
                  }"
                  v-model="formExtranjero.circunscripcion"
                  :options="circunscripciones"
                  value-field="cod_circunscripcion"
                  text-field="circunscripcion"
                  ><b-form-select-option :value="null" disabled
                    >seleccionar circunscripción</b-form-select-option
                  ></b-form-select
                >
                <!-- <model-list-select
                  ref="circuncripcion"
                  :isError="$v.formExtranjero.circunscripcion.$invalid"
                  v-model="formExtranjero.circunscripcion"
                  :list="circunscripciones"
                  option-value="cod_circunscripcion"
                  option-text="circunscripcion"
                  placeholder="seleccionar circuncripcion"
                >
                </model-list-select> -->
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="ubicacion != 'chile-geo'">
          <b-col sm="6">
            <b-form-group>
              <legend class="label-gobierno">
                Correo Electrónico (de uso personal y/o privado)
                <span class="text-danger">(*)</span>
              </legend>
              <b-form-input
                :class="{
                  'invalid-gobierno': $v.formComun.email.$invalid,
                  'input-gobierno': !$v.formComun.email.$invalid,
                }"
                v-model="formComun.email"
                type="text"
                placeholder="Ingresar Email"
                required
              ></b-form-input>
              <p
                v-if="!$v.formComun.email.maxLength"
                class="invalid-feedback mb-0"
              >
                El maximo permitido es de 50 caracteres
              </p>
              <p
                v-if="!$v.formComun.email.required"
                class="invalid-feedback mb-0"
              >
                El correo es requerido
              </p>
              <p v-if="!$v.formComun.email.email" class="invalid-feedback mb-0">
                Formato de correo incorrecto
              </p>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <legend class="label-gobierno">
                Confirmar correo electrónico
                <span class="text-danger">(*)</span>
              </legend>
              <b-form-input
                :class="{
                  'invalid-gobierno': $v.formComun.email2.$invalid,
                  'input-gobierno': !$v.formComun.email2.$invalid,
                }"
                v-model="formComun.email2"
                type="text"
                placeholder="Ingresar Email"
                required
              ></b-form-input>
              <p
                v-if="!$v.formComun.email2.required"
                class="invalid-feedback mb-0"
              >
                Confirmar correo es requerido
              </p>
              <p
                v-if="!$v.formComun.email2.email"
                class="invalid-feedback mb-0"
              >
                Formato de correo incorrecto
              </p>
              <p
                v-if="!$v.formComun.email2.sameAs"
                class="invalid-feedback mb-0"
              >
                Los correos no son iguales
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group>
              <legend class="label-gobierno">
                Teléfono <span class="text-danger">(*)</span>
              </legend>
              <b-input-group
                :prepend="ubicacion === 'extranjero' ? '+' : '+56'"
                class="mb-2 mr-sm-2 mb-sm-0"
              >
                <b-form-input
                  :class="{
                    'invalid-gobierno': $v.formComun.telefono.$invalid,
                    'input-gobierno': !$v.formComun.telefono.$invalid,
                  }"
                  v-model="formComun.telefono"
                  type="text"
                  :placeholder="
                    ubicacion === 'extranjero' ? 'Ej:5622222222' : 'Ej:22222222'
                  "
                  required
                >
                </b-form-input>
                <p
                  v-if="!$v.formComun.telefono.required"
                  class="invalid-feedback mb-0"
                >
                  El Teléfono es requerido
                </p>
                <p
                  v-if="!$v.formComun.telefono.maxLength"
                  class="invalid-feedback mb-0"
                >
                  El maximo permitido es de 12 caracteres
                </p></b-input-group
              >
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-if="
            ubicacion == 'chile-urbano' ||
            ubicacion == 'chile-rural' ||
            ubicacion == 'chile-geo'
          "
        >
          <b-col>
            <b-form-group label="Notificación">
              <b-form-checkbox
                v-model="formChileUrbano.notificacion_correo"
                name="notificacion_correo"
                :value="true"
                :unchecked-value="false"
                :checked="formChileUrbano.notificacion_correo"
                class="check-gobierno"
              >
                Solicito que la notificación del cambio de domicilio electoral
                se envíe a mi correo electrónico
                <!-- Autorizo a Servel a enviar información electoral a mi correo electrónico (mesa y local de votación, entre otros) -->
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="ubicacion == 'extranjero'">
          <b-col>
            <b-form-group>
              <p>
                * La notificación del cambio de su domicilio electoral será
                enviada al correo electrónico informado.
              </p>
              <!-- Autorizo a Servel a enviar información electoral a mi correo electrónico (mesa y local de votación, entre otros) -->
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <b-button
                v-if="ubicacion == 'chile-geo'"
                :disabled="
                  $v.formChileGeo.$invalid ||
                  $v.formComun.$invalid ||
                  otrasValidaciones ||
                  !enComuna
                "
                @click="sendToForm()"
                block
                variant="primary"
                >Solicito mi cambio de domicilio electoral.</b-button
              >
              <b-button
                v-if="ubicacion == 'chile-urbano'"
                :disabled="
                  $v.formChileUrbano.$invalid ||
                  $v.formComun.$invalid ||
                  otrasValidaciones
                "
                @click="sendToForm()"
                block
                variant="primary"
                >Solicito mi cambio de domicilio electoral.</b-button
              >
              <b-button
                v-if="ubicacion == 'chile-rural'"
                :disabled="
                  $v.formChileRural.$invalid ||
                  $v.formComun.$invalid ||
                  otrasValidaciones
                "
                @click="sendToForm()"
                block
                variant="primary"
                >Solicito mi cambio de domicilio electoral.</b-button
              >
              <b-button
                v-if="ubicacion == 'extranjero'"
                :disabled="
                  $v.formExtranjero.$invalid ||
                  $v.formComun.$invalid ||
                  otrasValidaciones ||
                  (!incorporacion && registro.nacionalidad != 'CL')
                "
                @click="sendToForm()"
                block
                variant="primary"
                >Solicito mi cambio de domicilio electoral.</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { ModelListSelect } from "vue-search-select";
import { format } from "rut.js";
import { mapState, mapActions } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import GoogleAutoComplete from "./../mapa/GoogleAutoComplete.vue";
import { gmapApi } from "vue2-google-maps";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import localizaciones from "./../../../public/localizaciones.json";

window.gm_authFailure = () => {
  return false;
};

const computed = {
  google: gmapApi,
  ...mapState("registro", ["registro"]),
  ...mapState("domicilio", [
    "ubicacion",
    "formChileGeo",
    "formChileUrbano",
    "formChileRural",
    "formExtranjero",
    "formComun",
    "regiones",
    "comunas",
    "paises",
    "ciudades",
    "territorios",
    "circunscripciones",
    "localidades",
  ]),
  ...mapState("mapa", ["domicilios", "calles"]),
};

const watch = {
  "formChileGeo.calle": async function (val) {
    if (!val) {
      this.class_calle = "is-invalid";
      return (this.estadoDelAuto = false);
    }

    this.class_calle = "is-valid";

    this.estadoDelAuto = true;
  },
  "formComun.email": async function (val) {
    if (!val) return;
    this.formComun.email = String(val).toLowerCase();
  },
  "formComun.email2": async function (val) {
    if (!val) return;
    this.formComun.email2 = String(val).toLowerCase();
  },
  "formChileUrbano.region": async function (val) {
    if (!val) return;
    await this.getComunas(val);
  },
  "formChileGeo.region": async function (val) {
    if (!val) return;
    let region = this.regiones.find((reg) => reg.cod_region == val);
    this.formChileGeo.cod_region = region.cod_region;
    await this.getComunas(val);
  },
  "formChileRural.region": async function (val) {
    if (!val) return;
    await this.getComunas(val);
  },
  "formExtranjero.pais": async function (val) {
    if (!val) return;
    await this.getTerritorios(val);
  },
  "formExtranjero.division_territorial": async function (val) {
    if (!val) return;
    await this.getCiudad(val);
  },
  "formExtranjero.ciudad": async function (val) {
    if (!val) return;
    await this.getCircuncripciones(val);
    await this.setCalles([]);
  },
  "formChileUrbano.comuna": async function (val) {
    if (!val) return;
    this.cod_comuna = val;
    await this.getLocalidad(val);
    await this.setCalles([]);
    this.otro = this.localidades.find((lo) => lo.localidad === "OTRO");
    this.formChileUrbano.geo = {
      position: {
        lat: parseFloat(this.otro.latitud),
        lng: parseFloat(this.otro.longitud),
      },
    };
    this.mapZoom = 12;
    this.marcas = [this.formChileUrbano.geo];
  },
  "formChileRural.comuna": async function (val) {
    if (!val) return;
    await this.getLocalidad(val);
    this.otro = this.localidades.find((lo) => lo.localidad === "OTRO");
    this.formChileRural.geo = {
      position: {
        lat: parseFloat(this.otro.latitud),
        lng: parseFloat(this.otro.longitud),
      },
    };
    this.mapZoom = 12;
    this.marcas = [this.formChileRural.geo];
  },
  "formChileGeo.comuna": async function (val) {
    // console.log("comu", val);

    if (!val) {
      this.formChileGeo.cod_comuna = null;
      this.formChileGeo.region_nom = null;
      this.formChileGeo.comuna = null;

      return;
    } else {
      let comuna = this.comunas.find((com) => com.comuna == val);
      //    console.log("comuna", comuna);
      this.formChileGeo.cod_comuna = comuna.cod_comuna;
      this.formChileGeo.region_nom = comuna.region;
      this.formChileGeo.comuna = comuna.comuna;
    }
  },
  // exacto: async function (val) {
  //   if (!val) {
  //     if (this.ubicacion === "chile-urbano") {
  //       this.formChileUrbano.geo = {
  //         position: {
  //           lat: parseFloat(this.otro.latitud),
  //           lng: parseFloat(this.otro.longitud),
  //         },
  //       };
  //       this.mapZoom = 10;
  //       this.marcas = [this.formChileUrbano.geo];
  //     }
  //     if (this.ubicacion === "chile-rural") {
  //       this.formChileRural.geo = {
  //         position: {
  //           lat: parseFloat(this.otro.latitud),
  //           lng: parseFloat(this.otro.longitud),
  //         },
  //       };
  //       this.mapZoom = 10;
  //       this.marcas = [this.formChileRural.geo];
  //     }
  //   }
  // },
  ischile: async function (val) {
    this.cambiar("");
  },
};

export default {
  name: "Solicitar",
  props: ["ischile", "incorporacion", "otrasValidaciones"],
  components: {
    ModelListSelect,
    GoogleAutoComplete,
  },
  computed,
  watch,
  data: function () {
    return {
      defaultPosition: { position: { lat: -33.4727092, lng: -70.7699143 } },
      autocomletelocal: "",
      estadoDelAuto: false,
      mapZoom: 3,
      marcas: [],
      location: null,
      cod_comuna: null,
      loading: true,
      domicilioSearch: "",
      calleSearch: "",
      class_calle: "is-invalid",
      selectedCalle: "",
      selectedDomicilio: "",
      key: process.env.VUE_APP_ATLAS_KEY,
      service: null,
      geocode: null,
      address: null,
      enComuna: true,
      original: null,
      localizaciones,
      comunaSeleccionada: "",
      falloGeo: false,
      otro: null,
      exacto: false,
      localidadSeleccionada: null,
      coordOriginal: true,
      estadoCreditos: process.env.VUE_APP_ESTADO_CREDITOS,
      mapaActivo: true,
      checkOtro: false,
      ultimaCoordenada: null,
      mapaCargado: false,
      residenciaOpciones: [
        { text: "Departamento", value: "departamento" },
        { text: "Block", value: "block" },
        { text: "Letra Casa", value: "casa" },
        { text: "Piso", value: "piso" },
        { text: "No aplica", value: "" },
      ],
    };
  },
  validations: {
    ubicacion: { required },
    formComun: {
      email: { required, email, maxLength: maxLength(50) },
      email2: { required, email, sameAs: sameAs("email") },
      telefono: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(12),
        numeric,
      },
    },
    formChileGeo: {
      comuna: { required },
      region: { required },
      cod_region: { required },
      cod_comuna: { required },
      calle: { required, maxLength: maxLength(300) },
      block_depto: { maxLength: maxLength(120) },
    },
    formChileUrbano: {
      region: { required },
      comuna: { required },
      calle: { required, maxLength: maxLength(120) },
      block_depto: { maxLength: maxLength(120) },
      numero: { maxLength: maxLength(10) },
      villa_poblacion: { required, maxLength: maxLength(120) },
    },
    formChileRural: {
      region: { required },
      comuna: { required },
      calle: { required, maxLength: maxLength(120) },
      numero: { maxLength: maxLength(10) },
      localidad: { required, maxLength: maxLength(120) },
    },
    formExtranjero: {
      pais: { required },
      division_territorial: { required },
      ciudad: { required },
      calle: { required, maxLength: maxLength(120) },
      block_depto: { maxLength: maxLength(120) },
      numero: { maxLength: maxLength(10) },
      circunscripcion: { required },
    },
  },
  async mounted() {
    this.enComuna;
    await this.$gmapApiPromiseLazy();

    await Promise.all([this.getRegiones("CL"), this.getPaises()]);

    this.geocode = await new this.google.maps.Geocoder();
    //35.4478561,-106.2120321
    this.formChileGeo.geo = this.defaultPosition;

    let email = "";
    if (this.registro && this.registro.email) {
      email = this.registro.email.toLowerCase();
      this.formComun.email = email;
      this.formComun.email2 = email;
    }

    window.gm_authFailure = () => {
      this.mapaActivo = false;
      if (this.ubicacion === "chile-geo") {
        this.cambiar("chile-urbano");
      }
    };
  },
  methods: {
    ...mapActions("mapa", ["setDomicilios", "setCalles"]),
    ...mapActions("domicilio", [
      "getLocalidad",
      "getPaises",
      "getTerritorios",
      "getCiudad",
      "getCircuncripciones",
      "getComunas",
      "getRegiones",
      "setCalle",
      "changeUbicacion",
      "setComunaNull",
    ]),
    async clearinputAuto() {
      // this.$refs.refcomuna.$children[0].searchText = "";
      await this.setComunaNull([]);
      this.enComuna = true;
      this.mapZoom = 3;
      this.formChileGeo.geo = this.defaultPosition;
      this.formChileGeo.calle = null;
      this.formChileGeo.direccion_estandar = null;
      this.marcas = [];
      this.formChileGeo.calle = null;
      this.formChileGeo.region = null;
      this.formChileGeo.comuna = null;
      this.formChileGeo.cod_comuna = null;
      this.formChileGeo.cod_region = null;
      this.cod_comuna = null;
      this.comuna = null;

      // console.log("object");
    },
    async clearChileGeo() {
      console.log("Limpio:::::.", this.formChileGeo);
      if (this.formChileGeo) {
        await this.setComunaNull([]);
        this.formChileGeo.region = null;
        this.formChileGeo.comuna = null;
        this.formChileGeo.cod_comuna = null;
        this.formChileGeo.cod_region = null;
      }
    },
    gSetPosition(data) {
      this.original = data;
      // console.log("setPosisicion::::::::::::::::", this.original);
      if (!data || !data.lat || !data.lng) {
        this.mapZoom = 3;
        this.formChileGeo.geo = this.defaultPosition;
        this.formChileGeo.calle = null;
        this.formChileGeo.direccion_estandar = null;
        this.marcas = [];
        this.formChileGeo.respuesta_google = null;
        this.coordOriginal = false;
        return;
      }
      this.formChileGeo.respuesta_google = data.respuesta;
      this.mapZoom = 16;
      this.formChileGeo.geo = { position: { lat: data.lat, lng: data.lng } };
      this.ultimaCoordenada = { position: { lat: data.lat, lng: data.lng } };
      this.enComuna = true;
      this.marcas = [this.formChileGeo.geo];
      this.coordOriginal = true;
    },
    async gMapFunc(evnt) {
      // console.log("movio pincho a:::", evnt.lat());
      let position = {
        lat: evnt.lat(),
        lng: evnt.lng(),
      };
      if (this.ubicacion === "chile-geo") {
        this.buscaDireccion(position);
      }
      if (this.ubicacion === "chile-urbano") {
        this.validarComunaManual(this.formChileUrbano, evnt);
      }
      if (this.ubicacion === "chile-rural") {
        this.validarComunaManual(this.formChileRural, evnt);
      }
      this.coordOriginal = false;
    },
    formatDireccion() {
      let domicilio = "";

      if (this.registro.calle) domicilio = `${this.registro.calle}`;
      if (this.registro.numero) domicilio += ` ${this.registro.numero}`;
      if (this.registro.resto) domicilio += ` ${this.registro.resto}`;
      if (this.registro.nombre_comuna)
        domicilio += `, ${this.registro.nombre_comuna}`;
      if (this.registro.nombre_region)
        domicilio += `, ${this.registro.nombre_region}`;

      domicilio += ` CIRCUNSCRIPCIÓN : ${this.registro.nombre_circuns}`;

      return domicilio;
    },
    formatNombre() {
      let nombre = ``;

      if (this.registro.paterno) nombre += this.registro.paterno;
      if (this.registro.materno) nombre += " " + this.registro.materno;
      if (this.registro.nombres) nombre += " " + this.registro.nombres;

      return nombre;
    },
    formatRun() {
      return format(`${this.registro.cedula}-${this.registro.dv}`);
    },
    async cambiar(ubicacion) {
      console.log("Ubicacion");
      // this.clearinputAuto()
      await this.changeUbicacion(ubicacion);
      if (ubicacion == "extranjero") {
        await this.clearChileGeo();
        this.falloGeo = false;
        // const ref = this.$refs.circuncripcion.$children[0].$refs.input
        // ref.setAttribute("autocomplete", "nope")
      }
      if (ubicacion == "chile-urbano") {
        await this.clearChileGeo();
        this.formChileUrbano.geo = this.defaultPosition;
        this.mapZoom = 3;
        this.exacto = false;
        // const ref = this.$refs.chileurbanoCalle.$refs.input

        // ref.setAttribute("autocomplete", "nope")
      }
      if (ubicacion == "chile-geo") {
        this.mapZoom = 3;
        this.formChileGeo.geo = this.defaultPosition;
        this.formChileGeo.calle = null;
        this.formChileGeo.direccion_estandar = null;
        this.falloGeo = false;
        this.exacto = false;

        this.marcas = [];
        // const ref = this.$refs.chileurbanoCalle.$refs.input

        // ref.setAttribute("autocomplete", "nope")
      }
      if (ubicacion == "chile-rural") {
        await this.clearChileGeo();
        this.falloGeo = false;
        this.formChileRural.geo = this.defaultPosition;
        this.exacto = false;
      }
    },
    async handleClick(event) {
      // console.log("en Comuna", this.enComuna);
      // this.formChileGeo.geo.position.lat = event.latLng.lat
      // this.formChileGeo.geo.position.lng = event.latLng.lng
      // this.marcas = [this.formChileGeo.geo]
    },
    async sendToForm() {
      console.log("this.formChileGeo");

      try {
        if (this.ubicacion === "chile-urbano") {
          if (!this.exacto) {
            if (
              !this.formChileUrbano.villa_poblacion ||
              this.formChileUrbano.villa_poblacion === "OTRO"
            ) {
              this.formChileUrbano.origen = `MANUAL_URBANO_LOC_OTRO${
                this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
              }`;
            } else {
              this.formChileUrbano.origen = `MANUAL_URBANO_TXT_LIBRE${
                this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
              }`;
            }
          } else {
            this.formChileUrbano.origen = `MANUAL_URBANO_LOC_EXACTA${
              this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
            }`;
          }
        }
        if (this.ubicacion === "chile-rural") {
          if (!this.exacto) {
            if (
              !this.formChileRural.localidad ||
              this.formChileRural.localidad === "OTRO"
            ) {
              this.formChileRural.origen = `MANUAL_RURAL_LOC_OTRO${
                this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
              }`;
            } else {
              this.formChileRural.origen = `MANUAL_RURAL_TXT_LIBRE${
                this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
              }`;
            }
          } else {
            this.formChileRural.origen = `MANUAL_RURAL_LOC_EXACTA${
              this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
            }`;
          }
        }

        if (this.ubicacion !== "extranjero" && this.mapaActivo) {
          if (
            (this.ubicacion === "chile-urbano" &&
              this.formChileUrbano.origen !==
                "MANUAL_URBANO_TXT_LIBRE_ORIGINAL" &&
              this.formChileUrbano.origen !==
                "MANUAL_URBANO_LOC_OTRO_ORIGINAL") ||
            (this.ubicacion === "chile-rural" &&
              this.formChileRural.origen !==
                "MANUAL_RURAL_LOC_OTRO_ORIGINAL") ||
            this.ubicacion === "chile-geo"
          ) {
            await this.resetZoom();
            const refMapa = this.$refs.mapa;
            const screenshot = (
              await html2canvas(refMapa, {
                scale: 1,
                windowWidth: 300,
                windowHeight: 300,
                width: 200,
                height: 200,
                useCORS: true,
              })
            ).toDataURL("image/jpeg", 0.8);
            this.formComun.screenshot = screenshot;
          }
        }
      } catch {
        if (this.ubicacion === "chile-urbano") {
          this.formChileUrbano.geo = null;
          this.formChileUrbano.cod_localidad = null;
          this.formChileUrbano.origen = "COORD_NO_EXISTE";
        }
        if (this.ubicacion === "chile-rural") {
          this.formChileRural.geo = null;
          this.formChileRural.cod_localidad = null;
          this.formChileRural.origen = "COORD_NO_EXISTE";
        }
      }
      if (
        this.ubicacion == "extranjero" &&
        this.registro.nacionalidad != "CL"
      ) {
        Swal.fire({
          icon: "error",
          type: "error",
          text: "Electores extranjeros solo pueden realizar cambio de domicilio dentro de territorio nacional",
          confirmButtonText: "Aceptar",
        });
        return;
      }
      if (this.formChileGeo.region && this.formChileGeo.comuna) {
        await this.validacionGeneral(this.formChileGeo);
      } else {
        await this.comprobarCoordenada();
        this.$emit("sendToForm");
      }
    },
    buscaDireccion(data) {
      // console.log("psisicion original:::::", this.formChileGeo);
      try {
        this.geocode.geocode({ location: data }, (results, status) => {
          if (status === "OK") {
            // console.log(results);
            if (results[0]) {
              let comuna_nueva;
              if (
                results[1].address_components.find(
                  (ad) => ad.types[0] == "locality"
                )
              ) {
                // console.log("locality");
                comuna_nueva = results[1].address_components.find(
                  (ad) => ad.types[0] == "locality"
                ).long_name;
              } else {
                // console.log("administrative_area_level_3");
                comuna_nueva = results[1].address_components.find(
                  (ad) => ad.types[0] == "administrative_area_level_3"
                ).long_name;
              }
              let comuna_antigua;
              if (
                this.formChileGeo.respuesta_google.address_components.find(
                  (ad) => ad.types[0] == "locality"
                )
              ) {
                comuna_antigua =
                  this.formChileGeo.respuesta_google.address_components.find(
                    (ad) => ad.types[0] == "locality"
                  ).long_name;
              } else {
                comuna_antigua =
                  this.formChileGeo.respuesta_google.address_components.find(
                    (ad) => ad.types[0] == "administrative_area_level_3"
                  ).long_name;
              }

              // console.log("COMUNA NUEVA", comuna_nueva);
              // console.log("COMUNA ANTIGUA", comuna_antigua);

              // console.log(
              //   "Respuesta autocompletar: ",
              //   this.formChileGeo.respuesta_google.formatted_address
              // );
              // console.log("Respuesta pin: ", results[1].formatted_address);
              if (comuna_antigua == comuna_nueva) {
                this.enComuna = true;
                this.ultimaCoordenada = {
                  position: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                };
                this.formChileGeo.geo = this.ultimaCoordenada;
                this.formChileGeo.respuesta_google.place_id =
                  results[0].place_id;
                // this.original.lat =results[0].geometry.location.lat()
                // this.original.lng =results[0].geometry.location.lng()
              } else {
                if (
                  results[0].formatted_address
                    .toLowerCase()
                    .includes(comuna_antigua.toLowerCase())
                ) {
                  this.enComuna = true;
                  this.ultimaCoordenada = {
                    position: {
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng(),
                    },
                  };
                  this.formChileGeo.geo = this.ultimaCoordenada;
                  this.formChileGeo.respuesta_google.place_id =
                    results[0].place_id;
                } else {
                  this.enComuna = false;
                  Swal.fire({
                    icon: "error",
                    type: "error",
                    text: "El punto indicado en el mapa queda fuera de los límites de la comuna seleccionada. Rectifique o el punto o la comuna registrada",
                    confirmButtonText: "Aceptar",
                  });
                  // this.formChileGeo = this.formChileGeo;
                  // this.cambiar("chile-urbano");
                  // let comuna = this.comunas.find(
                  //   (com) => com.comuna == this.formChileGeo.comuna
                  // );
                  // this.formChileUrbano.region = this.formChileGeo.region;
                  // this.formChileUrbano.comuna = comuna.cod_comuna;
                  // this.falloGeo = true;
                  // this.clearinputAuto();
                  this.gSetPosition(this.original);
                }
              }
            } else {
              window.alert("No se encontraron resultados");
            }
          } else {
            window.alert("Geocoder falló debido a: " + status);
          }
        });
      } catch (error) {
        // console.log("fin::::", error);
      }
    },
    async validacionGeneral(datos) {
      let comuna_chi = datos.comuna.toLowerCase();
      let comuna_goo = null;

      if (
        datos.respuesta_google.address_components.find(
          (ad) => ad.types[0] == "locality"
        )
      ) {
        comuna_goo = datos.respuesta_google.address_components
          .find((ad) => ad.types[0] == "locality")
          .long_name.toLowerCase();
      } else {
        comuna_goo = datos.respuesta_google.address_components
          .find((ad) => ad.types[0] == "administrative_area_level_3")
          .long_name.toLowerCase();
      }
      // console.log("CHILE:::", comuna_chi);
      // console.log("GOOGL:::", comuna_goo);
      if (comuna_chi != comuna_goo) {
        const valida = await this.validacionLocalizaciones(
          comuna_chi,
          comuna_goo
        );
        // console.log("ES VALIDA:::::", valida);
        if (!valida) {
          const encuentra = datos.respuesta_google.formatted_address
            .toLowerCase()
            .includes(comuna_chi.toLowerCase());
          if (!encuentra) {
            let estado = false;
            // await Swal.fire({
            //   icon: "error",
            //   type: "error",
            //   title: "Las comunas seleccionadas no coinciden",
            //   text:
            //     "La comuna que registró no coincide con su selección a partir de las opciones que Google le entregó. Dado eso le solicitamos realice su cambio de domicilio a través del formulario manual (sin georreferenciación) haciendo clic",
            //   confirmButtonText: "Aquí!",
            //   confirmButtonColor: "#E13617",
            // }).then(function(result) {
            //   if (result.value == true) {
            //     estado = true;
            //   }
            // });
            estado = true;
            if (estado) {
              this.cambiar("chile-urbano");
              let comuna = this.comunas.find(
                (com) => com.comuna == this.formChileGeo.comuna
              );
              this.formChileUrbano.region = this.formChileGeo.region;
              this.formChileUrbano.comuna = comuna.cod_comuna;
              this.falloGeo = true;
              this.clearinputAuto();
            }
          } else {
            await this.comprobarCoordenada();
            this.formChileGeo.origen = `GEO${
              this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
            }`;
            this.$emit("sendToForm");
          }
        } else {
          await this.comprobarCoordenada();
          this.formChileGeo.origen = `GEO${
            this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
          }`;
          this.$emit("sendToForm");
        }
      } else {
        await this.comprobarCoordenada();
        this.formChileGeo.origen = `GEO${
          this.coordOriginal ? "_ORIGINAL" : "_MODIFICADO"
        }`;
        this.$emit("sendToForm");
      }

      // const region = this.regiones.find(re =>(re.cod_region  == datos.region))
      // let region_chi = region.region.toLowerCase();
      // let region_goo = comuna_goo = (datos.respuesta_google.address_components.find(ad => ad.types[0] == "administrative_area_level_1")).long_name.toLowerCase()

      // console.log("REGION chile", region_chi);
      // console.log("REGION google", region_goo);
      // if(region_chi != region_goo){
      //     let estado = false
      //     await Swal.fire({
      //     icon: 'error',
      //     type: 'error',
      //     title: 'La dirección no ha sido encontrada. ',
      //     text: 'Para continuar con tu cambio de domicilio ingresa al formulario manual haciendo clic' ,
      //     confirmButtonText: 'Aquí!',
      //     confirmButtonColor: '#E13617',
      //     }).then(function(result) {
      //         if (result.value == true){
      //             estado = true
      //           }
      //     })
      //      if(estado){
      //         this.cambiar('chile-urbano')
      //     }

      // }
    },
    async validacionLocalizaciones(comuna_chi, comuna_goo) {
      // console.log("entro aca para validar", comuna_chi,  "-", comuna_goo);
      let estado = false;
      const comuna_limpia = await this.sinTildes(comuna_chi);
      // console.log("comuna limpia", comuna_limpia);
      const comuna_valida_goo = localizaciones.find(
        (lo) => lo.NOM_COMUNA_GOOGLE.toLocaleLowerCase() == comuna_goo
      );
      const comuna_valida_chile = localizaciones.find(
        (lo) => lo.NOM_COMUNA_GOOGLE.toLocaleLowerCase() == comuna_limpia
      );

      if (comuna_valida_goo && comuna_valida_chile) {
        if (comuna_valida_goo.NOM_COMUNA && comuna_valida_chile.NOM_COMUNA) {
          if (comuna_valida_goo.NOM_COMUNA == comuna_valida_chile.NOM_COMUNA) {
            estado = true;
          } else {
            estado = false;
          }
        }
        if (!comuna_valida_goo.NOM_COMUNA || !comuna_valida_chile.COD_COMUNA) {
          estado = false;
        }
      }
      return estado;
    },
    async sinTildes(texto) {
      return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async selectComuna(event) {
      if (event) {
        if (this.ubicacion === "chile-geo") {
          this.comunaSeleccionada = event;
        } else {
          let comuna = this.comunas.find((com) => com.cod_comuna == event);
          this.comunaSeleccionada = comuna.comuna;
          if (this.ubicacion == "chile-urbano") {
            this.formChileUrbano.villa_poblacion = null;
            this.$refs.check_otro.$children[0].searchText = "";
          } else if (this.ubicacion == "chile-rural") {
            this.formChileRural.localidad = null;
            this.$refs.check_otro.$children[0].searchText = "";
          }
        }
        return Swal.fire({
          icon: "info",
          type: "info",
          html:
            "Usted ha seleccionado la comuna de <b>" +
            this.comunaSeleccionada +
            "</b>. ¿Está seguro de que es correcta? Considere que el local de votación en el que votará quedará ubicado en esta comuna.",
          confirmButtonText: "Aceptar",
        });
      }
    },
    async selectLocalidad(event) {
      const seleccionado = this.localidades.find(
        (lo) => lo.localidad === event
      );
      this.ultimaCoordenada = {
        position: {
          lat: parseFloat(seleccionado.latitud),
          lng: parseFloat(seleccionado.longitud),
        },
      };
      if (this.ubicacion === "chile-urbano") {
        this.formChileUrbano.geo = this.ultimaCoordenada;
        this.formChileUrbano.cod_localidad = seleccionado.cod_localidad;

        this.marcas = [this.formChileUrbano.geo];
        this.original = this.formChileUrbano.geo;
        this.coordOriginal = true;
        if (seleccionado.localidad !== "OTRO") {
          this.formChileUrbano.villa_poblacion = seleccionado.localidad;
          this.formChileUrbano.origen = "MANUAL_URBANO_LOC_EXACTA";
          this.mapZoom = 15;
          this.exacto = true;
        } else {
          this.mapZoom = 12;
          this.exacto = false;
          this.formChileUrbano.villa_poblacion = "";
        }
        return;
      }
      if (this.ubicacion === "chile-rural") {
        this.formChileRural.geo = this.ultimaCoordenada;
        this.formChileRural.cod_localidad = seleccionado.cod_localidad;

        this.marcas = [this.formChileRural.geo];
        this.original = this.formChileUrbano.geo;
        this.coordOriginal = true;
        if (seleccionado.localidad !== "OTRO") {
          this.formChileRural.localidad = seleccionado.localidad;
          this.formChileRural.origen = "MANUAL_RURAL_LOC_EXACTA";
          this.mapZoom = 15;
          this.exacto = true;
        } else {
          this.mapZoom = 12;
          this.exacto = false;
          this.formChileRural.localidad = "";
        }
        return;
      }
    },
    async checkAutocomplete() {
      if (!this.formChileGeo.calle) {
        this.cambiar("chile-urbano");
        let comuna = this.comunas.find(
          (com) => com.comuna == this.formChileGeo.comuna
        );
        this.formChileUrbano.region = this.formChileGeo.region;
        this.formChileUrbano.comuna = comuna.cod_comuna;
        this.falloGeo = true;
        this.clearinputAuto();
      }
    },
    async validarComunaManual(datos, geo) {
      try {
        this.geocode.geocode({ location: geo }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              let comuna_nueva;
              if (
                results[0].address_components.find(
                  (ad) => ad.types[0] == "locality"
                )
              ) {
                // console.log("locality");
                comuna_nueva = results[0].address_components.find(
                  (ad) => ad.types[0] == "locality"
                ).long_name;
              } else {
                // console.log("administrative_area_level_3");
                comuna_nueva = results[0].address_components.find(
                  (ad) => ad.types[0] == "administrative_area_level_3"
                ).long_name;
              }
              let comuna_antigua = this.comunas
                .find((com) => com.cod_comuna == datos.comuna)
                .comuna.toLowerCase();

              // console.log("COMUNA NUEVA", comuna_nueva);
              // console.log("COMUNA ANTIGUA", comuna_antigua);

              // console.log("Respuesta pin: ", results[0].formatted_address);
              if (comuna_antigua == comuna_nueva) {
                this.enComuna = true;
                this.ultimaCoordenada = {
                  position: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                };
                datos.geo = this.ultimaCoordenada;
              } else {
                if (
                  results[0].formatted_address
                    .toLowerCase()
                    .includes(comuna_antigua.toLowerCase())
                ) {
                  this.enComuna = true;
                  this.ultimaCoordenada = {
                    position: {
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng(),
                    },
                  };
                  datos.geo = this.ultimaCoordenada;
                  this.$refs.map.$mapObject.setCenter({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  });
                } else {
                  this.enComuna = false;
                  Swal.fire({
                    icon: "error",
                    type: "error",
                    text: "El punto indicado en el mapa queda fuera de los límites de la comuna seleccionada. Rectifique o el punto o la comuna registrada",
                    confirmButtonText: "Aceptar",
                  });
                  if (this.ubicacion == "chile-geo") {
                    this.ultimaCoordenada = {
                      position: {
                        lat: this.original.position.lat,
                        lng: this.original.position.lng,
                      },
                    };
                    datos.geo = this.ultimaCoordenada;
                    this.$refs.map.$mapObject.setCenter({
                      lat: this.original.position.lat,
                      lng: this.original.position.lng,
                    });
                  } else {
                    this.ultimaCoordenada = {
                      position: {
                        lat: this.marcas[0].position.lat,
                        lng: this.marcas[0].position.lng,
                      },
                    };
                    datos.geo = this.ultimaCoordenada;
                    this.$refs.map.$mapObject.setCenter(
                      this.marcas[0].position
                    );
                  }
                  this.marcas = [datos.geo];
                  this.enComuna = true;
                }
              }
            } else {
              window.alert("No se encontraron resultados");
            }
          } else {
            window.alert("Geocoder falló debido a: " + status);
          }
        });
      } catch (error) {
        // console.log("fin::::", error);
      }
    },
    async check($event) {
      if ($event) {
        this.localidadSeleccionada = "OTRO";
        this.selectLocalidad("OTRO");
        this.$refs.check_otro.$children[0].searchText = "OTRO";
      } else {
        this.localidadSeleccionada = "";
        this.$refs.check_otro.$children[0].searchText = "";
      }

      this.exacto = !$event;
    },

    async comprobarCoordenada() {
      if (
        this.ubicacion == "chile-geo" &&
        this.formChileGeo.geo.position.lat ===
          this.defaultPosition.position.lat &&
        this.formChileGeo.geo.position.lng === this.defaultPosition.position.lng
      ) {
        this.formChileGeo.geo = this.ultimaCoordenada;
      } else if (
        this.ubicacion == "chile-urbano" &&
        this.formChileUrbano.geo.position.lat ===
          this.defaultPosition.position.lat &&
        this.formChileUrbano.geo.position.lng ===
          this.defaultPosition.position.lng
      ) {
        this.formChileUrbano.geo = this.ultimaCoordenada;
      } else if (
        this.ubicacion == "chile-rural" &&
        this.formChileRural.geo.position.lat ===
          this.defaultPosition.position.lat &&
        this.formChileRural.geo.position.lng ===
          this.defaultPosition.position.lng
      ) {
        this.formChileRural.geo = this.ultimaCoordenada;
      }
    },

    async resetZoom() {
      this.mapaCargado = false;
      this.$refs.map.$mapObject.setCenter(this.ultimaCoordenada.position);
      this.$refs.map.$mapObject.setZoom(16);
      await this.esperar((_) => this.mapaCargado == true);
    },
    async cargaMapa() {
      this.mapaCargado = true;
    },
    async esperar(cond) {
      const poll = (resolve) => {
        if (cond()) resolve();
        else setTimeout((_) => poll(resolve), 200);
      };

      return new Promise(poll);
    },

    async resetComuna(data) {
      data.comuna = null;
      if (this.ubicacion == "chile-geo") {
        this.enComuna = true;
        this.mapZoom = 3;
        this.formChileGeo.geo = this.defaultPosition;
        this.formChileGeo.calle = null;
        this.formChileGeo.direccion_estandar = null;
        this.marcas = [];
        this.formChileGeo.calle = null;
        this.formChileGeo.comuna = null;
        this.formChileGeo.cod_comuna = null;
        this.cod_comuna = null;
        this.comuna = null;
        this.$refs.gAuto.address = null;
      }
    },
  },
};
</script>

<style>
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

li.vbst-item.list-group-item.list-group-item-action {
  margin: 0px !important;
}

.ui.selection.dropdown {
  border: 1px solid #000000 !important;
}

.input.search {
  border-radius: 1px;
  font-size: 16px;
}
</style>
